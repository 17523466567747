@import "variables";

.justify-between {
    justify-content: space-between;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-4 {
    margin-right: 1rem;
}

.ml-4 {
    margin-left: 1rem;
}

.shadow-cyan {
    box-shadow: $shadow-cyan;
}

.shadow {
    box-shadow: var(--shadow);
}

.flex {
    display: flex;
}

.bg-primary {
    background-color: var(--primary);
}

.hidden {
    display: none;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  .loader {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: $dark;
      width: 100vw;
      height: 100vh;
      z-index: 2000;
      justify-content: center;
      align-items: center;
      display: flex;
  }

  .w-full {
      width: 100%;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  

@media screen and (min-width: 768px) {
    .md\:flex {
        display: flex;
    }

    .md\:w-1\/2 {
        width: 50%;
    }
}