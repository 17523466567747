@import "variables";

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;700&display=swap');

@font-face {
  font-family: nulshock;
  src: url(./fonts/nulshock-bd.ttf);
}

::before, ::after, * {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
}

body {
    background-color: var(--bg-body);
    margin: 0 auto;
    padding: 0;
    font-family: 'Rajdhani', sans-serif;
    user-select: none;
    max-width: 100vw;
    font-size: 1rem;
}

a {
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    font-family: 'Rajdhani', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary); 
  }

.app {
    position: relative;
}

.bg-video {
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 530px;
}

.header {
    position: relative;
    height: 562px;
    overflow: hidden;
    .navbar {
        position: relative;
        z-index: 2;
        flex-grow: 1;
        display: flex;
        align-items: center;
        height: 141px;
        .menu {
            list-style: none;
            align-items: center;
            display: flex;
                li {
                    padding: 1rem 42px;
                    cursor: pointer;
                    border-bottom: 4px solid transparent;
                    text-transform: uppercase;
                    font-weight: bold;
                    a {
                        color: #fff;
                        position: relative;
                        font-size: 1.2rem;
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -9px;
                            left: 0;
                            display: inline-block;
                            width: 100%;
                            height: 4px;
                            background-color: var(--primary);
                            transform: scaleX(0);
                            transition: .3s cubic-bezier(.47,.11,0,.96);
                        }
                        &:hover::after {
                            transform: scaleX(1);
                        }
                    }
                    .dark {
                        border: 2px solid var(--primary);
                        height: 34px;
                        width: 59px;
                        margin-right: -2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.3s linear;
                        &:hover {
                          background-color: var(--primary);
                        }
                    }
                    .light {
                        height: 34px;
                        width: 59px;
                        padding: 0;
                        position: relative;
                        .sun {
                          top: 4px;
                          left: 14px;
                          z-index: 10;
                          position: absolute;
                        }
                        span {
                            height: 28px;
                            width: 55px;
                            .border-light {
                              position: absolute;
                              left: 0;
                              top: 0;
                              path {
                                stroke: var(--primary);
                              }
                            }
                        }
                        .bg-light-active {
                          position: absolute;
                          top: 1px;
                          left: 0;
                        }
                    }
                }
                .tool {
                    display: flex;
                    position: relative;
                    .tip {
                      position: absolute;
                      top: 80%;
                      z-index: 3000;
                      left: 44%;
                      .tip-text {
                        color: white;
                        position: absolute;
                        left: 122px;
                        font-size: 1.2rem;
                        bottom: -5px;
                        width: 252px;
                      }
                      .btn-skip {
                        border: 2px solid var(--primary);
                        padding: 7px 40px;
                        position: absolute;
                        color: var(--primary);
                        font-weight: bold;
                        font-size: 1.2rem;
                        top: 120px;
                        left: 120px;
                        transition: all 0.3s linear;
                        &:hover {
                          color: white;
                          background-color: var(--primary);
                        }
                      }
                    }
                }
        }
        .brand {
            padding: 0 2rem;
            .brand-image {
                width: 103px;
            }
        }
    }
    .catpunk11 {
        width: 276px;
        height: 533px;
        position: absolute;
        background-repeat: no-repeat;
        right: 0;
        background-size: cover;
        top: 0;
    }
    
    .copyright {
        color: #33FFFE;
        font-size: 10px;
        line-height: 23px;
        opacity: 0.7;
        position: absolute;
        bottom: 80px;
        left: 20px;
    }
}

.join-us {
  color: var(--primary);
  font-weight: 900;
  font-size: 28px;
  line-height: 29px;
  height: 74px;
  width: 251px;
  position: absolute;
  left: 267px;
  bottom: 262px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  display: none;
  svg {
    position: absolute;
    path {
      stroke: var(--primary);
    }
  }
}

.btn-primary {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    path {
      stroke: var(--primary);
    }
  }
}

.fade-step {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
}

@keyframes glitch-animation-1 {
    0% {
      clip: rect(71px, 350px, 27px, 30px);
    }
    5% {
      clip: rect(62px, 350px, 120px, 30px);
    }
    10% {
      clip: rect(109px, 350px, 38px, 30px);
    }
    15% {
      clip: rect(3px, 350px, 48px, 30px);
    }
    20% {
      clip: rect(82px, 350px, 93px, 30px);
    }
    25% {
      clip: rect(118px, 350px, 90px, 30px);
    }
    30% {
      clip: rect(41px, 350px, 49px, 30px);
    }
    35% {
      clip: rect(6px, 350px, 131px, 30px);
    }
    40% {
      clip: rect(52px, 350px, 82px, 30px);
    }
    45% {
      clip: rect(72px, 350px, 63px, 30px);
    }
    50% {
      clip: rect(148px, 350px, 85px, 30px);
    }
    55% {
      clip: rect(101px, 350px, 59px, 30px);
    }
    60% {
      clip: rect(72px, 350px, 120px, 30px);
    }
    65% {
      clip: rect(15px, 350px, 36px, 30px);
    }
    70% {
      clip: rect(55px, 350px, 25px, 30px);
    }
    75% {
      clip: rect(12px, 350px, 41px, 30px);
    }
    80% {
      clip: rect(50px, 350px, 23px, 30px);
    }
    85% {
      clip: rect(135px, 350px, 16px, 30px);
    }
    90% {
      clip: rect(14px, 350px, 50px, 30px);
    }
    95% {
      clip: rect(103px, 350px, 113px, 30px);
    }
    100% {
      clip: rect(55px, 350px, 105px, 30px);
    }
  }

  @keyframes glitch-animation-2 {
    0% {
      clip: rect(114px, 350px, 31px, 30px);
    }
    5% {
      clip: rect(101px, 350px, 38px, 30px);
    }
    10% {
      clip: rect(45px, 350px, 63px, 30px);
    }
    15% {
      clip: rect(61px, 350px, 24px, 30px);
    }
    20% {
      clip: rect(54px, 350px, 93px, 30px);
    }
    25% {
      clip: rect(55px, 350px, 149px, 30px);
    }
    30% {
      clip: rect(59px, 350px, 30px, 30px);
    }
    35% {
      clip: rect(113px, 350px, 78px, 30px);
    }
    40% {
      clip: rect(34px, 350px, 57px, 30px);
    }
    45% {
      clip: rect(106px, 350px, 110px, 30px);
    }
    50% {
      clip: rect(67px, 350px, 39px, 30px);
    }
    55% {
      clip: rect(118px, 350px, 28px, 30px);
    }
    60% {
      clip: rect(15px, 350px, 125px, 30px);
    }
    65% {
      clip: rect(104px, 350px, 57px, 30px);
    }
    70% {
      clip: rect(124px, 350px, 84px, 30px);
    }
    75% {
      clip: rect(39px, 350px, 87px, 30px);
    }
    80% {
      clip: rect(11px, 350px, 65px, 30px);
    }
    85% {
      clip: rect(65px, 350px, 76px, 30px);
    }
    90% {
      clip: rect(12px, 350px, 65px, 30px);
    }
    95% {
      clip: rect(147px, 350px, 41px, 30px);
    }
    100% {
      clip: rect(72px, 350px, 67px, 30px);
    }
  }

.features {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    .item {
        display: none;
    }
    .item.active {
        display: block;
        position: relative;
    }
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 40px;
    text-transform: uppercase;
    color: white;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: 'nulshock', sans-serif;
    position: relative;
    padding-left: 35px;
    &::before {
      content: '#catpunk';
      transform: rotate(-90deg);
      position: absolute;
      font-family: 'Rajdhani', sans-serif;
      font-size: 14px;
      left: -20px;
      font-weight: 500;
      bottom: 20px;
    }
}
.sub-title {
    color: white;
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 40px;
    position: relative;
    opacity: 0.7;
    transform: rotate(-90deg);
}

.sperator {
    width: 100%;
    position: absolute;
    left: 0;
    background-repeat: repeat-x;
    bottom: 7px;
    background-image: url(./imgs/bg-sperator.svg);
    background-repeat: repeat-x;
    background-size: cover;
    height: 46px;
    svg {
      width: 100%;
    }
    path {
      fill: var(--bg-body);
    }
}

.space {
    padding-left: 200px;
}

.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 30%;
    left: -3rem;
    align-items: center;
    .text {
        color: #fff;
        font-size: 15px;
        transform: rotate(90deg);
        line-height: 10px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
    }
    .line {
        background-color: var(--primary);
        height: 3px;
        transform: rotate(90deg);
        width: 60px;
        margin-top: 110px;
    }
    .icons {
        margin-top: 60px;
        .icon {
            margin-bottom: 40px;
            .link {
                display: block;
                path:hover{
                    fill: var(--primary);
                    transition-property: all;
                    transition-duration: 0.3s;
                }
            }
        }
    }
}

.logo {
    position: absolute;
    top: 103px;
    left: 25px;
    background-repeat: no-repeat;
    background-size: 100vw;
}

.character {
    position: absolute;
    top: 252px;
    right: 0;
}

.navbar-toggler { 
  margin-right: 30px;
}

.minting {
  z-index: 1;
  height: 74px;
  width: 251px;
  position: absolute;
  left: 550px;
  bottom: 262px;
  display: none;
  svg {
    path {
      stroke: rgb(194, 192, 192);
    }
  }
}

html[data-theme="dark"]  {
  .sperator {
    background-image: url('./imgs/bg-sperator-cyan.svg');
  }
  .character { 
    top: 245px;
  }
}

@media screen and (min-width: 768px) {
  .header {
    height: 953px;
    .catpunk11 {
      width: 569px;
      height: 1079px;
    }
    .copyright {
      font-size: 20px;
      left: 95px;
    }
  .minting {
    display: flex;
  }
  }
  .title {
    font-size: 70px;
    line-height: 84px;
    margin-bottom: 30px;
    &::before {
      font-size: 20px;
      left: -40px;
    }
  }
  .sub-title {
    font-size: 20px;
    line-height: 40px;
  }
  .navbar-toggler {
    display: none;
  }
  .logo {
    background-size: auto;
    width: 1330px;
    height: 730px;
  }
  .social {
    top: 40%;
    left: 0;
    .text {
      font-size: 20px;
      line-height: 38px;
      letter-spacing: 0.3em;
    }
    .line {
      margin-top: 140px;
    }
  }
  .bg-video { 
    height: 953px;
  }

  .btn-primary {
    font-size: 24px;
    line-height: 29px;
  }
  .sperator { 
    bottom: -10px;
  }
  .join-us {
    display: flex;
    margin-top: 400px;
  }
  html[data-theme="dark"]  {
    .character {
      top: 260px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .navbar {
      height: 99px;
      .nav-right {
        display: flex;
      }
      .navbar-toggler {
        position: absolute;
        z-index: 1001;
        right: 0.5rem;
        top: 2rem;
      }
      .menu {
        width: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
        transition: all 0.3s linear;
        background-color: $dark;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        height: 60%;
      }
      .menu.show {
        width: 100vw;
      }
      .brand {
        .brand-image {
          width: 75px;
        }
      }
    }
  }
  .fade-step, .tip {
    display: none;
  }
}

@media screen and (min-width: 1534px) {
  .header {
    height: 1080px;
  }
  .bg-video { 
    height: 1080px;
  }
  .sperator { 
    bottom: -5px;
  }
  .btn-primary {
    font-size: 28px;
  }
}

@media screen and (min-width: 1930px) { 
  .header {
    .navbar {
      height: 200px;
      .brand {
        padding: 0 4rem;
      }
    }
  }
}

.fade-load {
  background-color: var(--bg-body);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

.loader1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  margin: -80px 0px 0px -80px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #E3E4DC;
  // animation: rotate3 3s linear infinite;
  &:before {
    content: '';
    width: 164px;
    height: 164px;
    display: block;
    position: absolute;
    border: 2px solid #898a86;
    border-radius: 50%;
    top: -2px;
    left: -2px;
    box-sizing: border-box;
    // border-bottom-color: transparent;
    // border-left-color: transparent;
    // border-right-color: transparent;
    clip: rect(0px, 35px, 35px, 0px);
    z-index: 10;
    animation: rotate infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
  }
  &:after {
    content: '';
    width: 164px;
    height: 164px;
    display: block;
    position: absolute;
    border: 2px solid #c1bebb;
    border-radius: 50%;
    top: -2px;
    left: -2px;
    box-sizing: border-box;
    // transform: rotate(30deg);
    // border-bottom-color: transparent;
    // border-left-color: transparent;
    // border-right-color: transparent;
    clip: rect(0px, 164px, 150px, 0px);
    z-index: 9;
    // animation: rotate2 infinite, rotate3 infinite;
    // animation-duration: 3s;
    // animation-timing-function: linner;
    animation: rotate2 3s linear infinite;
  }
}

.hexagon-container {
  position: relative;
  top: 33px;
  left: 41px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.hexagon {
  position: absolute;
  width: 40px;
  height: 23px;
  background-color: #fafafa;
  &:before {
    content: "";
    position: absolute;
    top: -11px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 11.5px solid #fafafa;
  }
  &:after {
    content: "";
    position: absolute;
    top: 23px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 11.5px solid #fafafa;
  }
}

@each $index,
$top,
$left in (1, 0px, 0px),
(2, 0px, 42px),
(3, 36px, 63px),
(4, 72px, 42px),
(5, 72px, 0px),
(6, 36px, -21px),
(7, 36px, 21px) {
  $time: 3s; // thx to @zeakd for this formula
  $delay: $time / 14;
  .hexagon.hex_#{$index} {
    top: $top;
    left: $left;
    //cubic-bezier(.155,1.105,.295,1.12)
    animation: Animasearch $time ease-in-out infinite;
    animation-delay: $delay*$index;
  }
}

@keyframes Animasearch {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15%,
  50% {
    transform: scale(0.5);
    opacity: 0;
  }
  65% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
    clip: rect(0px, 35px, 35px, 0px);
  }
  50% {
    clip: rect(0px, 40px, 40px, 0px);
  }
  100% {
    transform: rotate(360deg);
    clip: rect(0px, 35px, 35px, 0px);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    clip: rect(0px, 164px, 150px, 0px);
  }
  50% {
    clip: rect(0px, 164px, 0px, 0px);
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
    clip: rect(0px, 164px, 150px, 0px);
  }
}

@keyframes rotate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
