.team {
    .container {
        padding: 50px 0;
    }
    .title {
        margin-bottom: 45px;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
    }
    .member {
        position: relative;
        margin: 13px;
        bottom: 0;
        &:hover {
            transition: all 0.3s linear;
            bottom: 1rem;
            left: 0;
        }
        .avatar {
            width: 100%;
            object-fit: cover;
        }
        .fade {
            position: absolute;
            background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.76) 100%);
            z-index: 10;
            width: 100%;
            height: 100%;
            top: 0;
        }
        .info {
            position: absolute;
            z-index: 20;
            bottom: 13px;
            padding: 21px 26px;
            .name {
                font-size: 30px;
                line-height: 40px;
                font-weight: bold;
                color: #FFF;
            }
            .job-title {
                color: #33FFFE;
                font-size: 20px;
                line-height: 40px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .team {
        .title {
            margin-bottom: 95px;
            max-width: 880px;
        }
        .container {
            min-height: 1489px;
            max-width: 1230px;
            display: flex;
            justify-content: center;
            margin: auto;
            flex-direction: column;
            padding: 150px 0;
        }
        .grid {
            margin: 0 -26px;
            display: flex;
            justify-content: center;
            .member {
                cursor: default;
                img {
                    width: 390px;
                    height: 390px;
                }
                .fade {
                    width: 390px;
                    height: 390px;
                }
            }
        }
    }
}