@import "variables";

.footer {
    background-color: $dark;
    .container {
        max-width: 1462px;
        margin: auto;
        height: 301px;
        position: relative;
        margin-bottom: 60px;
        background-color: #370072;
        overflow: hidden;
        .fade {
          position: absolute;
          height: 100%;
          background-repeat: no-repeat;
          left: 160px;
          width: 100%;
          z-index: 0;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          height: 100%;
          width: 100%;
          padding: 0 60px;
          top: 0;
          .logo-footer {
            width: 100%;
            margin-bottom: 2rem;
            img {
              width: 100%;
            }
          }
        }
    }

    .btn-primary {
      width: 294px;
      height: 74px;
    }
    
    .endless {
      margin: auto;
      font-size: 16px;
      font-weight: bold;
      align-items: center;
      justify-content: space-between;
      color: #FFF;
      background-color: #1c3e3d;
      text-transform: uppercase;
      padding: 1rem 0 0.5rem 0;
      text-align: center;
      div {
        margin-bottom: 0.5rem;
      }
    }
    
    .sperator {
      bottom: 0;
    }
}

@media screen and (min-width: 768px) {
  .footer {
    .container {
      margin-bottom: 120px;
      .content {
        flex-direction: row;
        justify-content: space-between;
        .logo-footer {
          margin: 0;
          img {
            width: 403px;
            height: 109px;
          }
        }
      }
    }
    .endless {
      height: 40px;
      display: flex;
      padding: 0 80px;
      div {
        margin: 0;
      }
    }
  }
}