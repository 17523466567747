:root {
    --primary: #FD24CA;
    --bg-body: #370072;
    --bg-primary-light: #6615BC;
    --light-primary: #FD24CA;
    --shadow: 0 0 26px -1px rgba(234, 46, 209, 0.43);
    --body-font: 'Rajdhani', sans-serif;
    --font-title: 'nulshock', sans-serif;
    --dark: #192423;
    --bg-time-tree: #F700FF;
}

$primary: #FD24CA;
$dark: #192423;
$shadow-cyan: 0 0 26px -1px rgba(46, 234, 220, 0.43);
$shadow-fuchsia: 0 0 26px -1px #fd24cae7;

html[data-theme="dark"]  {
    --primary: #00ddc7;
    --bg-body: #187084;
    --bg-primary-light: #32ACA7;
    --light-primary: #33FFFE;
    --shadow: 0 0 26px -1px rgba(46, 234, 220, 0.43);
    --bg-time-tree: #00FFEC;
}