.overview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(./imgs/bg-plans.svg);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: cover;
    background-position-y: 170px;
    .container {
        padding: 0.75rem;
        max-width: 1800px;
        margin: auto;
        .image {
            img {
                width: 100%;
                transform: scale(1);
                transition: all 0.5s linear;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        .flex-overview {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    .body {
        color: #FFF;
        opacity: 0.5;
        font-size: 1.125rem;
        line-height: 1.5;
        opacity: 0.7;
        max-width: 654px;
        margin-bottom: 30px;
    }
    .overtext {
        height: 100%;
    }
    .btn-primary {
        width: 150px;
        height: 74px;
        svg {
            width: 150px;
        }
    }
}

.haha {
    color: white;
}

.overview.cyan {
    background-image: url(./imgs/bg-plans-cyan.svg);
}

@media screen and (min-width: 768px) {
    .overview {
        min-height: 1100px;
        .container {
            .flex-overview {
                flex-direction: row;
            }
            .body {
                font-size: 30px;
                line-height: 40px;
            }
            .image {
                img {
                    width: 666px;
                    height: 573px;
                }
            }
        }
        .btn-primary {
            width: 251px;
            height: 74px;
            svg {
                width: 251px;
                height: 74px;
            }
        }
    }
}

@media screen and (min-width: 1536px) {
    .overview {
        .container {
            .image {
                img {
                    width: 999px;
                    height: 860px;
                }
            }
            .overtext {
                padding-top: 200px;
            }
        }
    }
}

@media screen and (min-width: 1930px) { 
    .overview {
        min-height: 1500px;
        background-size: unset;
        background-position-y: bottom;
    }
}