.plans {
    padding: 1rem 0.75rem 0 0.75rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    .container {
        margin: auto;
        .body {
            color: #fff;
            opacity: 0.7;
            font-size: 1.125rem;
            line-height: 1.5;
            max-width: 654px;
            margin-bottom: 0.75rem;
        }
    }
    .action {
        margin-bottom: 1rem;
    }
    .btn-primary {
        height: 74px;
        width: 150px;
        svg {
            width: 150px;
        }
    }
    img {
        max-width: 100%;
    }
    .video {
        padding: 2rem 0;
        position: relative;
        .icon {
            position: absolute;
            top: 40%;
            left: 40%;
            svg {
                width: 48px;
                height: 48px;
            }
        }
        .bg {
            svg {
                width: 98%;
                height: auto;
            }
            path {
                fill: var(--bg-primary-light);
            }
        }
    }

    .read-more-detail {
        &:hover {
            text-decoration: underline;
        }
    }
    
    .bg-load-v {
        position: relative;
        padding: 0 1rem;
        background-color: black;
        &::before {
            content: '';
            height: 4rem;
            width: 4rem;
            position: absolute;
            transform: rotate(45deg);
            bottom: -2rem;
            left: -2rem;
            background-color: var(--bg-body);
        }
        &::after {
            content: '';
            height: 2rem;
            width: 2rem;
            position: absolute;
            transform: rotate(45deg);
            top: -1rem;
            right: -1rem;
            background-color: var(--bg-body);
        }
    }
    .pricing-video {
        height: auto;
        width: 90%;
        margin: auto;
    }
}

@media screen and (min-width: 768px) { 
    .plans {
        padding: 202px 0 0 0;
        margin-bottom: 202px;
        .container {
            max-width: 1100px;
            .gap-content {
                gap: 2rem;
            }
        }
        .bg-load-v { 
            padding: 0 6rem;
            &::before {
                height: 10rem;
                width: 10rem;
                bottom: -5rem;
                left: -5rem;
            }
            &::after { 
                height: 4rem;
                width: 4rem;
                top: -2rem;
                right: -2rem;
            }
        }
    }
}

@media screen and (min-width: 1534px) {
    .plans {
        padding: 202px 0 0 0;
        .container {
            max-width: 1500px;
            .body {
                font-size: 29px;
                line-height: 45px;
                margin-bottom: 55px;
            }
        }
        .btn-primary {
            height: 74px;
            width: 251px;
            svg {
                width: 251px;
                height: 74px;
            }
        }
        .video {
            .bg {
                svg {
                    width: 674px;
                    height: 423px;
                }
            }
            .icon {
                svg {
                    width: 98px;
                    height: 98px;
                }
            }
        }
    }
}