@import "variables";

.faq {
    background-color: $dark;
    min-height: 1300px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    a {
        color: var(--primary);
    }
    .container {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        .body {
            max-width: 1700px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: auto;
        }
    }
    .title {
        margin-bottom: 60px;
        padding-right: 70px;
        .sub-right {
            font-size: 20px;
            font-weight: 500;
            transform: rotate(-90deg);
            position: absolute;
            font-family: var(--body-font);
            right: 0;
            bottom: 20px;
        }
        &::before {
            content: '';
        }
    }
    .wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        transition-property: all;
        transition-duration: 0.5s;
        &:hover {
            cursor: pointer;
        }
        .item {
            width: 100%;
            background-repeat: no-repeat;
            border: 1px solid #a22487;
            color: #FFF;
            font-weight: bold;
            position: relative;
            .head {
                font-size: 20px;
                line-height: 40px;
                padding: 0.5rem 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .content {
                font-size: 20px;
                line-height: 40px;
                overflow: hidden;
                transition: all 0.5s linear;
                padding: 0 20px;
                height: 0;
                opacity: 0;
            }
            .content.show {
                height: 320px;
                overflow: visible;
                opacity: 1;
            }
        }
    }
}

.slash-top {
    position: absolute;
    right: -11px;
    border-bottom: 1px solid #a22487;
    background-color: #192423;
    width: 26px;
    height: 20px;
    top: -8px;
    transform: rotate(45deg);
}

.slash-bottom {
    position: absolute;
    left: -11px;
    border-top: 1px solid #a22487;
    background-color: #192423;
    width: 26px;
    height: 20px;
    bottom: -8px;
    transform: rotate(45deg);
}

html[data-theme="dark"]  {
    .slash-top {
        border-bottom: 1px solid #29a7a6;
    }
    .slash-bottom {
        border-top: 1px solid #29a7a6;
    }
    .faq {
        .wrap {
            .item {
                border: 1px solid #29a7a6;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .faq {
        .body {
            .wrap {
                margin: 0 15px 20px 15px;
                .item {
                    .content.show {
                        height: 160px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .faq {
        padding: 50px 0.5rem;
        .wrap {
            width: 100%;
            .item {
                background-size: 100%;
            }
        }
    }
}