@import "variables";

.gallery {
    background-color: $dark;
    position: relative;
    background-repeat: no-repeat;
    overflow-x: hidden;
    .container {
        max-width: 1760px;
        margin: auto;
        padding: 50px 0.5rem;
    }
    .title {
        margin-bottom: 50px;
    }
    .tool {
        display: flex;
        .btn-slide {
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid var(--light-primary);
            margin-right: -1px;
            box-shadow: var(--shadow);
            cursor: pointer;
            transition: background 0.3s ease-out;
            &:hover {
                background-color: var(--primary);
                svg {
                  path {
                    fill: white;
                  }
                }
            }
            svg {
              path {
                fill: var(--light-primary);
              }
            }
        }
    }
    .btn-join {
        height: 60px;
        width: 187px;
        font-size: 23px;
        text-transform: uppercase;
        color: white;
        background-color: var(--primary);
        position: relative;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: skewX(-30deg);
        bottom: 84px;
        &:hover {
            animation: skew-glitch 750ms infinite;
            transition: all .3s ease-in;
            .scan-glitch {
                animation: scan-glitch 2s infinite;
            }
            .text {
                margin-left: 0.5rem;
            }
        }
        .text {
            margin-right: 17px;
            font-weight: 900;
            transform: skewX(30deg);
        }
        .icon {
            transform: skewX(30deg);
        }
        .scan-glitch {
            top: 0;
            width: 100%;
            box-shadow: rgba(212, 207, 201, 0.75) 0 0 0.9375rem 2px;
            background: #d4cfc9;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: -1.75rem;
            z-index: 2;
        }
    }
    .im-gl {
      border-radius: 0.5rem;
      box-shadow: var(--shadow);
    }
}

.swiper-container {
  overflow-x: unset;
  overflow-y: unset;
}
@media screen and (min-width: 768px) {
  
  .swiper-container {
    overflow: hidden;
  }

  .gallery {
    .tour {
      img {
        max-height: 100%;
      }
    }
    .title {
      max-width: 433px;
    }
    .btn-join {
      height: 95px;
      width: 338px;
      bottom: 100px
    }
    .tool {
      .btn-slide {
        width: 64px;
        height: 64px;
      }
    }
  }
}

  @media screen and (min-width: 1534px) {
      .gallery {
        .container {
          padding: 100px 1rem;
        }
        .title {
            margin-bottom: 150px;
        }
        .swiper-container {
            overflow: unset;
        }
          .btn-join {
            bottom: 150px;
            font-size: 38px;
          }
      }
  }

  @keyframes scan-glitch {
    0% {
      top: 0;
    }
    50% {
      top: 95%;
    }
    100% {
      top: 100%;
    }
  }
  @keyframes skew-glitch {
      0%,
      10%,
      100%,
      20%,
      50%,
      55%,
      60%,
      80%,
      90% {
        -webkit-transform: skewX(-30deg);
        transform: skewX(-30deg);
      }
      5%,
      75% {
        -webkit-transform: skewX(-28deg);
        transform: skewX(-28deg);
      }
      15% {
        -webkit-transform: skewX(-35deg);
        transform: skewX(-35deg);
      }
      45% {
        -webkit-transform: skewX(-27deg);
        transform: skewX(-27deg);
      }
      85% {
        -webkit-transform: skewX(-38deg);
        transform: skewX(-38deg);
      }
    }

  @keyframes tournament-image-anim-1 {
    0% {
      clip: rect(600px, 960px, 1rem, 0);
    }
    4% {
      clip: rect(16rem, 960px, 18rem, 0);
    }
    8% {
      clip: rect(25rem, 960px, 11rem, 0);
    }
    12% {
      clip: rect(29rem, 960px, 26rem, 0);
    }
    16% {
      clip: rect(26rem, 960px, 7rem, 0);
    }
    20% {
      clip: rect(28rem, 960px, 15rem, 0);
    }
    24% {
      clip: rect(20rem, 960px, 22rem, 0);
    }
    28% {
      clip: rect(12rem, 960px, 17rem, 0);
    }
    32% {
      clip: rect(25rem, 960px, 26rem, 0);
    }
    36% {
      clip: rect(8rem, 960px, 8rem, 0);
    }
    40% {
      clip: rect(6rem, 960px, 15rem, 0);
    }
    44% {
      clip: rect(9rem, 960px, 8rem, 0);
    }
    48% {
      clip: rect(22rem, 960px, 11rem, 0);
    }
    52% {
      clip: rect(8rem, 960px, 28rem, 0);
    }
    56% {
      clip: rect(1rem, 960px, 8rem, 0);
    }
    60% {
      clip: rect(28rem, 960px, 11rem, 0);
    }
    64% {
      clip: rect(7rem, 960px, 22rem, 0);
    }
    68% {
      clip: rect(1rem, 960px, 10rem, 0);
    }
    72% {
      clip: rect(6rem, 960px, 29rem, 0);
    }
    76% {
      clip: rect(600px, 960px, 28rem, 0);
    }
    80% {
      clip: rect(8rem, 960px, 29rem, 0);
    }
    84% {
      clip: rect(25rem, 960px, 10rem, 0);
    }
    88% {
      clip: rect(28rem, 960px, 5rem, 0);
    }
    92% {
      clip: rect(16rem, 960px, 29rem, 0);
    }
    96% {
      clip: rect(6rem, 960px, 8rem, 0);
    }
    100% {
      clip: rect(6rem, 960px, 2rem, 0);
    }
  }

  .swiper-slide {
    filter: grayscale(100%);
  }

  .swiper-slide-active {
    filter: none;
    transform: scale(1.125);
    bottom: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;
  }